import { MenuItemsType, DropdownMenuItemType } from '@hunnyswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], account: string) => ConfigMenuItemsType[] = (t) => {
  return [
    {
      label: t('Trade'),
      icon: 'Swap',
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Exchange'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ],
    },
    {
      label: t('Farms'),
      href: '/farms',
      icon: 'Earn',
      showItemsOnMobile: false,
      items: [
        // {
        //   label: t('Farms'),
        //   href: '/farms',
        // },
        // {
        //   label: t('LP Migration'),
        //   href: '/lp-migration',
        // },
      ],
    },
    {
      label: t('Lock'),
      href: '/lock',
      icon: 'Treasury',
      showItemsOnMobile: false,
      items: [
        // {
        //   label: t('Lock'),
        //   href: '/lock',
        // },
        // {
        //   label: t('Pools'),
        //   href: '/pools',
        // },
      ],
    },
    {
      label: t('Bridge'),
      href: '/bridge',
      icon: 'Bridge',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: '',
      href: '',
      icon: 'More',
      hideSubNav: true,
      items: [
        // {
        //   label: t('Info'),
        //   href: '/info',
        // },
        // {
        //   label: t('IFO'),
        //   href: '/ifo',
        // },
        // {
        //   label: t('Voting'),
        //   href: '/voting',
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        // {
        //   label: t('Leaderboard'),
        //   href: '/teams',
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        {
          label: t('Blog'),
          href: 'https://blog.hunny.finance/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Docs'),
          href: 'https://hunny.rcom.by/s__docs/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Github'),
          href: 'https://github.com/HunnyFinance/hunnyswap',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ],
    },
  ]
}

export default config
