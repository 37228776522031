import { ChainId, Token } from '@hunnyswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  wbnb: new Token(
    MAINNET,
    '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    18,
    'WAVAX',
    'Wrapped Avalanche',
    'https://www.avax.network/',
    {
      logoURI: '/images/tokens/AVAX.png',
    },
  ),
  cake: new Token(
    MAINNET,
    '0xf9430aD8da4af7fb4091C57FE523af1236FF5b2C',
    18,
    'XOXO',
    'Hunny XOXO Token',
    'http://hunny.rcom.by/',
    {
      logoURI: '/images/tokens/XOXO.png',
    },
  ),
  gXoxo: new Token(
    TESTNET,
    '0xcBa8201Df53C950ca4f513fDF3530De020C0E227',
    18,
    'gXOXO',
    'Hunny gXOXO Token',
    'http://hunny.rcom.by/',
    {
      logoURI: '/images/tokens/XOXO.png',
    },
  ),
  busd: new Token(
    MAINNET,
    '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    6,
    'USDC',
    'USDC Coin',
    'https://www.centre.io/usdc',
    {
      logoURI: '/images/tokens/USDC.png',
    },
  ),
  usdt: new Token(
    MAINNET,
    '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
    6,
    'USDT',
    'USDT Coin',
    '',
    {
      logoURI: '/images/tokens/USDT.png',
    },
  ),
  usdtE: new Token(
    MAINNET,
    '0xc7198437980c041c805a1edcba50c1ce5db95118',
    6,
    'USDT.e',
    'USDT.e Coin',
    '',
    {
      logoURI: '/images/tokens/USDT.png',
    },
  ),
  eth: new Token(
    MAINNET,
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    18,
    'ETH',
    'Ethereum',
    'https://ethereum.org/en/',
    {
      logoURI: '/images/tokens/ETH.png',
    },
  ),
  btc: new Token(
    MAINNET,
    '0x50b7545627a5162f82a992c33b87adc75187b218',
    8,
    'BTC',
    'Bitcoin',
    '',
    {
      logoURI: '/images/tokens/BTC.png',
    }
  ),
  bnb: new Token(
    MAINNET,
    '0x264c1383ea520f73dd837f915ef3a732e204a493',
    18,
    'BNB',
    'Binance',
    'https://binance.com',
    {
      logoURI: '/images/tokens/BNB.png',
    }
  )
} as const)

export const testnetTokens = defineTokens({
  wbnb: new Token(
    TESTNET,
    '0x3a34E91226052C509CB0DC38dCDEFe6A028F869E',
    18,
    'WETH',
    'Wrapped Ethereum',
    'https://ethereum.org/',
  ),
  cake: new Token(
    TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
    'http://hunny.rcom.by/',
  ),
  gXoxo: new Token(
    TESTNET,
    '0x2Cf6c365046A1546199AC3cedBd38E4710A982d5',
    18,
    'CAKE',
    'PancakeSwap Token',
    'http://hunny.rcom.by/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    '',
    'SyrupBar Token',
    'http://hunny.rcom.by/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
} as const)

const tokens = () => {
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }

  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
