import { ChainId } from '@hunnyswap/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 1

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://snowtrace.io',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
}

export const UINT256_MAX =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

// XOXO_PER_BLOCK details
// 40 XOXO is minted per block
// 20 XOXO per block is sent to Burn pool (A farm just for burning cake)
// 10 XOXO per block goes to XOXO syrup pool
// 9 XOXO per block goes to Yield farms and lottery
// XOXO_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// XOXO/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const XOXO_PER_BLOCK = 0.0025
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const XOXO_PER_YEAR = XOXO_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'http://hunny.rcom.by'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000


export const CHAIN_ID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID, 10)
